import {environment} from '../../../environments/environment';
import {ForumPost} from './forum-post';

export class User {


	id = 0;
	uuid = '';
	inaric = '';
	departmentCode = '';
	picture = '';
	role = '';
	level = 0;

	// * Profil
	firstName = '';
	lastName = '';
	birthdate = '';
	birthdateShow: any = new Date();
	address = '';
	zipCode = '';
	city = '';
	email = '';
	phoneNumber = '';
	employer = '';

	// Mon Syndicat de rattachement
	syndicat = '';
	syndicatAddress = '';
	syndicatZipCode = '';
	syndicatCity = '';
	syndicatEmail = '';
	syndicatPhoneNumber = '';
	syndicatPresident = '';

	// Ma fédération
	federation = '';

	// Mon UD de rattachement
	UD = '';
	UDAddress = '';
	UDZipCode = '';
	UDCity = '';
	UDEmail = '';
	UDPhoneNumber = '';
	UDPresident = '';

	// Fonction syndical
	syndicalFunction = '';

	// Fonction syndical
	responsability = '';


	region = '';
	department = '';
	directoryVisible = false;

	optIn = false;
	cguAccepted = false;
	prospectingUsable = false;
	visibility: object = {
		birthdate: false,
		address: false,
		zipCode: false,
		city: false,
		email: false,
		phoneNumber: false,
		employer: false,
	};

	geoLat = 0;
	geoLon = 0;
	geoCity = '';
	geoDepartment = '';

	createdAt = '';
	updatedAt = '';
	removedAt = '';

	events: Event[] = [];
	participations: [] = [];
	posts: ForumPost[] = [];

	select = false;
	connected = false;
	cronTimestamp = new Date().getTime();
	currentTimestamp = new Date().getTime();
	distance = 0;
	notificationEnabled = false;
	notificationToken = '';
	fullName = '';
	fullNameInverse = '';
	blocked = false;
	isSuperAdmin = false;
	notificationOptions = {
		forum: false,
		offer: false,
		message: false,
		event: false,
	};
	cookieEnabled = true;
	cookieOptions = {
		facebook: false,
		analytics: false,
	};
	ssoId = '';

	constructor(data = null) {
		if (data) {
			this.id = data.id;
			this.uuid = data.uuid;
			this.inaric = data.inaric;
			this.ssoId = data.ssoId;
			this.notificationEnabled = data.notificationEnabled;
			this.notificationToken = data.notificationToken;
			this.isSuperAdmin = data.isSuperAdmin;
			this.departmentCode = data.departmentCode;
			this.picture = data.picture;
			this.role = data.role;
			this.level = data.level;
			this.firstName = data.firstName;
			this.lastName = data.lastName;
			this.birthdate = data.birthdate;
			this.birthdateShow = data.birthdate;
			if (data.birthdate && data.birthdate !== '-') {
				const y = data.birthdate.substring(0, 4);
				const m = parseInt(data.birthdate.substring(4, 6), null) - 1;
				const d = data.birthdate.substring(6);
				this.birthdateShow = new Date(y, m, d);
			}
			this.address = data.address;
			this.zipCode = data.zipCode;
			this.city = data.city;
			this.email = data.email;
			this.phoneNumber = data.phoneNumber;
			this.employer = data.employer;
			this.syndicat = data.syndicat;
			this.syndicatAddress = data.syndicatAddress;
			this.syndicatZipCode = data.syndicatZipCode;
			this.syndicatCity = data.syndicatCity;
			this.syndicatEmail = data.syndicatEmail;
			this.syndicatPhoneNumber = data.syndicatPhoneNumber;
			this.syndicatPresident = data.syndicatPresident;
			this.federation = data.federation;
			this.UD = data.UD;
			this.distance = data.distance;
			this.UDAddress = data.UDAddress;
			this.UDZipCode = data.UDZipCode;
			this.UDCity = data.UDCity;
			this.UDEmail = data.UDEmail;
			this.UDPhoneNumber = data.UDPhoneNumber;
			this.UDPresident = data.UDPresident;
			this.syndicalFunction = data.syndicalFunction;
			this.responsability = data.responsability;
			this.region = data.region;
			this.department = data.department;
			this.directoryVisible = data.directoryVisible;
			this.optIn = data.optIn;
			this.cguAccepted = data.cguAccepted;
			// this.cguAccepted = true;
			this.prospectingUsable = data.prospectingUsable;
			this.geoLat = data.geoLat;
			this.geoLon = data.geoLon;
			this.geoCity = data.geoCity;
			this.geoDepartment = data.geoDepartment;
			this.createdAt = data.createdAt;
			this.updatedAt = data.updatedAt;
			this.removedAt = data.removedAt;
			this.cronTimestamp = data.cronTimestamp;
			this.currentTimestamp = data.currentTimestamp;
			this.fullName = data.lastName + ' ' + data.firstName;
			this.fullNameInverse = data.firstName + ' ' + data.lastName;
			if (data.blocked !== undefined) {
				this.blocked = data.blocked;
			}
			if (data.events !== undefined) {
				this.events = data.events.map(item => {
					return new Event(item);
				});
			}

			if (data.participations !== undefined) {
				this.participations = data.participations.map(item => {
					return new Event(item);
				});
			}
			if (data.posts !== undefined) {
				this.posts = data.posts.map(item => {
					return new ForumPost(item);
				});
			}

			if (data.visibility !== undefined && data.visibility && Object.keys(data.visibility).length > 0) {
				this.visibility = data.visibility;
			}
			if (data.notificationOptions !== undefined && data.notificationOptions) {
				this.notificationOptions = data.notificationOptions;
			}
			if (data.analyticsEnabled !== undefined && data.analyticsEnabled) {
				this.cookieOptions.analytics = data.analyticsEnabled;
			}
			if (data.facebookAnalyticsEnabled !== undefined && data.facebookAnalyticsEnabled) {
				this.cookieOptions.facebook = data.facebookAnalyticsEnabled;
			}
			this.cookieEnabled = false;
			if (this.cookieOptions.facebook || this.cookieOptions.analytics) {
				this.cookieEnabled = true;
			}
		}

		if (this.inaric !== '') {
			this.picture = environment.host + 'media/inaric/' + this.inaric;
			//  + '?cache=' + new Date().getTime();
		} else {

			if (this.picture === '' || this.picture === undefined) {
				this.picture = '/assets/image/empit-user.png';

			} else {
				this.picture = environment.host + 'media/' + this.picture;
				//  + '?cache=' + new Date().getTime();

			}
		}
	}
}
