import {Component, OnInit, ViewChild} from '@angular/core';
import {AlertController, ModalController, PopoverController} from '@ionic/angular';
import {ResponsiveService} from '../../../shared/services/utils/responsive.service';
import {Event} from '../../../shared/models/event';
import {EventService} from '../../../shared/services/event.service';
import {Share} from '@capacitor/share';
import {Router} from '@angular/router';
import {AlertHelper} from '../../../shared/helpers/alert.helper';
import {User} from '../../../shared/models/user';
import {UserService} from '../../../shared/services/user.service';
import {ToastService} from '../../../shared/services/utils/toast.service';
import {MessengerService} from '../../../shared/services/messenger.service';
import {ModalService} from '../../../shared/services/utils/modal.service';
import {
	PopoverMessageComponent
} from 'src/app/main/pages/chat/components/popovers/popover-message/popover-message.component';
import {Comment} from 'src/app/shared/models/comment';
import {Browser} from '@capacitor/browser';
import {CommonHelper} from "../../../shared/helpers/common.helper";

@Component({
	selector: 'app-modal-event',
	templateUrl: './event.component.html',
	styleUrls: ['./event.component.scss'],
})
export class EventModalComponent implements OnInit {
	event: Event = new Event();
	title = '';
	error = '';
	comment = '';
	user = new User();
	isJoin = false;

	@ViewChild('popover') popover;
	isOpen = false;

	constructor(
		private router: Router, private toastService: ToastService,
		private alertHelper: AlertHelper, public modalController: ModalController,
		public responsive: ResponsiveService, public eventService: EventService,
		private commonHelper: CommonHelper,
		private userService: UserService, private messengerService: MessengerService,
		private modalService: ModalService, private popoverController: PopoverController,
		private alertController: AlertController) {

	}


	ngOnInit(): void {
		this.init();
	}

	init() {
		this.user = this.userService.getUserAuthValue();
		this.eventService.get(this.event.uuid).subscribe((event: Event) => {
			this.event = event;
			this.checkIsJoin();
		});
	}

	openEdit() {
		this.modalService.openEventCreate(this.event, 'Evénements', '', value => {
			this.init();
		});
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}

	sendComment(text) {
		if (this.user.inaric === '') {
			this.modalService.openAccess(value => {
			});
		} else {
			if (this.comment === '') {
				return;
			}
			this.eventService.comment(this.event.uuid, this.comment).subscribe(value => {
				this.comment = '';
				this.init();
			}, error => {
				this.toastService.show(error);
			});
		}
	}

	sendSignalement() {
		this.modalService.openSignalement((data: any) => {
			if (data.data.result) {
				this.eventService.signal(this.event.uuid, data.data.result).subscribe(value => {
				}, error => {
					this.toastService.show(error);
				});
			}
		});

	}

	async share() {
		let text = 'Bonjour,\n';
		text += 'Je pense que cet événement CFTC pourrait t\'intéresser :\n';
		text += this.event.title + '\n';
		text += 'Date : ' + this.event.startAtDay + ' ' + this.event.startAtDayNumber + ' ' + this.event.startAtMonth + ' ' + this.event.startAtYears + ' ' + this.event.startAtHours + ':' + this.event.startAtMinutes;
		if (this.event.stopAt !== '' && this.event.stopAt !== undefined) {
			text += ' à ' + this.event.stopAtHours + ':' + this.event.stopAtMinutes;
		}
		text += '\n';
		text += 'Lieu : ' + this.event.address + ' ' + this.event.city + ' ' + this.event.zipCode + '\n';
		text += 'Description : ' + this.event.description + '\n';
		text += 'Télécharge CFTC l\'App : https://www.cftc.fr/cftc-app' + '\n';
		text += 'CFTC - CFTC l\'App - Rejoignez notre réseau de 140 000 adhérents et militants Cette application a été pensée pour tous, que vous soyez adhérents ou non, pour se retrouver, renforcer les liens, et être solidaires.';

		await Share.share({
			title: this.event.title,
			text,
			dialogTitle: 'CFTC Evenement',
		})
		;
	}

	join() {
		this.eventService.join(this.event.uuid).subscribe(value => {
			/*for (const participant of value.participants) {
				if (participant.uuid === this.user.uuid) {
					this.toastService.show('Vous recevrez désormais des notifications en lien avec cet événement');
					break;
				}
			}*/
			this.init();
		}, error => {
			this.toastService.show(error);
		});
	}

	checkIsJoin() {
		this.isJoin = false;
		if (this.event.participants === undefined) {
			return;
		}
		for (const participant of this.event.participants) {
			if (participant.uuid === this.user.uuid) {
				this.isJoin = true;
			}
		}
	}

	openProfile(user) {
		this.modalService.openProfile(user, 'Evénement', value => {
		});
	}

	async openChat(user) {
		if (this.user.inaric === '') {
			await this.modalService.openAccess(value => {
			});
			return;
		} else {
			this.messengerService.create('', [user]).subscribe(value => {
				this.router.navigate([(this.responsive.isMobile() ? '/chat/' : '/chat-d/') + value.uuid]);
				this.dismissModal();
			}, error => {
				this.toastService.show(error);
			});
		}
	}

	async openMenu(ev, comment: Comment) {


		const popover = await this.popoverController.create({
			component: PopoverMessageComponent,
			componentProps: {
				buttons: [
					{
						key: 'edit',
						name: 'Modifier',
						icon: 'alert-circle-outline',
						show: this.user.uuid === comment.owner.uuid || this.user.isSuperAdmin
					},
					{
						key: 'del',
						name: 'Supprimer',
						icon: 'alert-circle-outline',
						show: this.user.uuid === comment.owner.uuid || this.user.isSuperAdmin
					}
				]
			},
			cssClass: 'popover-mobile-cftc',
			event: ev,
			mode: 'ios',
			translucent: true
		});

		popover.onDidDismiss().then((dataReturned) => {
			if (dataReturned && dataReturned.data && dataReturned.data.button) {
				const button = dataReturned.data.button;
				switch (button.key) {
					case 'edit' :
						this.modalService.openEditComment(comment, 'event', (data: any) => {
							if (data.data.comment) {
								this.init();
							}
						});
						break;
					case 'del' :
						this.presentAlertConfirmDeleteComment(comment);
						break;
				}
			}
		});
		await popover.present();
	}

	async openMenuAll(ev, event: Event) {


		const popover = await this.popoverController.create({
			component: PopoverMessageComponent,
			componentProps: {
				buttons: [
					{
						key: 'edit',
						name: 'Modifier',
						icon: 'alert-circle-outline',
						show: true
					},
					{
						key: 'del',
						name: 'Supprimer',
						icon: 'close-outline',
						show: this.user.uuid === event.owner.uuid || this.user.isSuperAdmin
					},
				]
			},
			cssClass: 'popover-mobile-cftc',
			event: ev,
			mode: 'ios',
			translucent: true
		});

		popover.onDidDismiss().then((dataReturned) => {
			if (dataReturned && dataReturned.data && dataReturned.data.button) {
				const button = dataReturned.data.button;
				switch (button.key) {
					case 'edit' :
						this.modalService.openEventCreate(event, 'Evénements', '', (data: any) => {
							if (data.data.event) {
								this.init();
							}
						});
						break;
					case 'del' :
						this.presentAlertConfirmDelete(event);
						break;
				}
			}
		});
		await popover.present();
	}

	async presentAlertConfirmDeleteComment(comment: Comment) {
		const alert = await this.alertController.create({
			cssClass: 'signalement',
			header: 'Êtes-vous sûr de vouloir supprimer ce commentaire?',
			buttons: [
				{
					text: 'Annuler',
					role: 'cancel',
					handler: () => {

					},
				},
				{
					text: 'Supprimer',
					handler: (data) => {
						this.eventService.deleteComment(comment.uuid).subscribe(value => {
							this.init();
						}, error => {
							this.toastService.show(error);
						});
					},
				},
			],
		});

		await alert.present();
	}

	async presentAlertConfirmDelete(event: Event) {
		const alert = await this.alertController.create({
			cssClass: 'signalement',
			header: 'Êtes-vous sûr de vouloir supprimer cette événement?',
			buttons: [
				{
					text: 'Annuler',
					role: 'cancel',
					handler: () => {

					},
				},
				{
					text: 'Supprimer',
					handler: (data) => {

						this.eventService.delete(event.uuid).subscribe(value => {
							this.dismissModal();
						}, error => {
							this.toastService.show(error);
						});
					},
				},
			],
		});

		await alert.present();
	}

	openFile(file) {
		this.commonHelper.openFile(file);
	}

	async openVisio(visioUrl) {
		const prefix = 'http';
		if (visioUrl.substr(0, prefix.length) !== prefix) {
			visioUrl = 'https://' + visioUrl;
		}
		await Browser.open({url: visioUrl});
	}

	openPopover(e: any) {
		e.stopPropagation();
		this.popover.event = e;
		this.isOpen = true;
	}
}
