import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService} from './utils/http.service';
import {PaginationService} from './pagination';
import {Ressourcerie} from '../models/ressourcerie';


@Injectable({
	providedIn: 'root'
})
export class RessourcerieService extends PaginationService {

	ressourceries: BehaviorSubject<Array<Ressourcerie>>;
	contributions: BehaviorSubject<Array<Ressourcerie>>;
	personalizes: BehaviorSubject<Array<Ressourcerie>>;
	userContributions: BehaviorSubject<Array<Ressourcerie>>;

	constructor(private https: HttpService) {
		super();
		this.ressourceries = new BehaviorSubject<Array<Ressourcerie>>([]);
		this.contributions = new BehaviorSubject<Array<Ressourcerie>>([]);
		this.personalizes = new BehaviorSubject<Array<Ressourcerie>>([]);
		this.userContributions = new BehaviorSubject<Array<Ressourcerie>>([]);
	}

	getRessourcerie(type: 'productions' | 'productions-personalize' | 'contributions', codeInaric: string = '', page: number = 1): Observable<any> {
		if (page === 1) {
			switch(type) {
				case 'productions':
					this.ressourceries.next([]);
				case 'productions-personalize':
					this.personalizes.next([])
				case 'contributions':
					if (codeInaric !== '') {
						this.userContributions.next([])
					} else {
						this.contributions.next([])
					}
			}
		}
		return  this.https.get(`ressourcerie/${page}/`  + type + (codeInaric !== '' ? '/' + codeInaric : '')).pipe(
			map((res: any) => {
				const newArray = res.map(item => {
					return new Ressourcerie(item);
				});
				switch(type) {
					case 'productions':
						let currentArrayR = this.ressourceries.getValue();
						this.ressourceries.next(currentArrayR.concat(newArray));
						return this.ressourceries.getValue();
					case 'productions-personalize':
						let currentArrayP = this.personalizes.getValue();
						this.personalizes.next(currentArrayP.concat(newArray));
						return this.personalizes.getValue();
					case 'contributions':
						if (codeInaric !== '') {
							let currentArrayUC = this.userContributions.getValue();
							this.userContributions.next(currentArrayUC.concat(newArray));
							return this.userContributions.getValue();
						} else {
							let currentArrayC = this.contributions.getValue();
							this.contributions.next(currentArrayC.concat(newArray));
							return this.contributions.getValue();
						}
				}

			})
		);
	}
}
