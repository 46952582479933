import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PaginationService} from './pagination';
import {HttpService} from './utils/http.service';
import {SubscriptionPaper} from '../models/subscriptionPaper';

@Injectable({
	providedIn: 'root'
})
export class SubscriptionService extends PaginationService {

	constructor(private http: HttpService) {
		super();
	}

	getNews(): Observable<any> {
		return this.http.get('subscription/news/').pipe(
			map((res: any) => {
				return res;
			})
		);

	}

	getPapers(inaric: string): Observable<any> {
		return this.http.get('subscription/paper/' + inaric).pipe(
			map((res: any) => {
				const newArray = [];
				for (const resKey in res) {
					if (res.hasOwnProperty(resKey)) {
						const items = res[resKey];
						if (items) {
							for (const item of items) {
								newArray.push(new SubscriptionPaper(item));
							}
						}
					}
				}
				return newArray;
			})
		);

	}

	updateNews(data): Observable<any> {
		return this.http.post('subscription/news', data).pipe(
			map((res: any) => {
				return res;
			})
		);
	}

	updatePaper(subscription: SubscriptionPaper, inaric: string): Observable<any> {
		return this.http.post('subscription/paper', {
			inaric: inaric,
			abonne: subscription.isSub ? 1 : 0,
			guid_pub: subscription.guidPub
		}).pipe(
			map((res: any) => {
				return res;
			})
		);
	}
}
