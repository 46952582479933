<ion-item lines="none" style="--background: white!important;--min-height: 66px;--max-height: 66px">
	<ion-icon (click)="dismissModal()" *ngIf="responsive.isMobile()" class="back-icon"
			  name="chevron-back-outline"></ion-icon>
	<ion-label class="back">Événement</ion-label>
	<ion-icon (click)="dismissModal()" *ngIf="responsive.isDesktop()" class="close-icon" name="close-outline"
			  slot="end"></ion-icon>
	<ion-button (click)="openMenuAll($event,event)"
				*ngIf="(this.user.uuid === event.owner.uuid || this.user.isSuperAdmin) && responsive.isMobile()"
				class="menu" slot="end"
				style="--background: white">
		<ion-icon name="ellipsis-horizontal">
		</ion-icon>
	</ion-button>
</ion-item>
<div *ngIf="responsive.isMobile()" style="overflow-y: auto;height: calc(100% - 152px - 25px);background: #f1f1f1">
	<ion-grid
		style="padding-left: 15px;padding-right: 15px;background: white;border-bottom-left-radius:15px;border-bottom-right-radius:15px; ">
		<ion-row>
			<ion-col class="title" size="12">
				{{ event.title }}
			</ion-col>
			<ion-col size="12">
				<ion-item class="categorie ion-no-margin ion-no-padding" lines="none"
						  style="--background: white!important;">
					<div [class.ion-avatar-mobile]="responsive.isMobile()" class="ion-avatar" slot="start">
						<img [src]="'assets/image/'+event.category+'-green-icon.png'">
					</div>
					{{ eventService.categories[event.category] }}
				</ion-item>
			</ion-col>
			<ion-col *ngIf="event.picture!='' && event.picture !== undefined" size="12">
				<div [style.background]="'url('+event.urlPicture+')'" class="image"></div>
			</ion-col>
			<ion-col class="info" size="12">
				<ion-icon name="calendar-clear-outline"></ion-icon>
				{{ event.startAtDay }}, {{ event.startAtDayNumber }} {{ event.startAtMonth }} <span
				*ngIf="event.stopAt">- {{ event.stopAtDay }},
				{{ event.stopAtDayNumber }} {{ event.stopAtMonth }}</span>
			</ion-col>
			<ion-col class="info" size="12">
				<ion-icon name="time-outline"></ion-icon>
				{{ event.startAt | date:'HH:mm' }} <span *ngIf="event.stopAt">- {{ event.stopAt | date:'HH:mm' }}</span>
			</ion-col>
			<ion-col *ngIf="event.capacity !== ''" class="info" size="12">
				<ion-icon name="people-outline"></ion-icon>
				{{ event.capacity }}
			</ion-col>
			<ion-col *ngIf="event.location !== '' && event.address !== '' && event.city !== ''&& event.zipCode !== ''"
					 class="info" size="12">
				<ion-icon name="location-outline"></ion-icon>
				{{ event.location }}, {{ event.address }} {{ this.event.city }} {{ this.event.zipCode }}
			</ion-col>
			<ion-col (click)="openFile(file)" *ngFor="let file of event.files" class="info" size="12">
				<ion-icon name="attach-outline"></ion-icon>
				<span class="link">{{ file.name }}</span>
			</ion-col>
			<ion-col *ngIf="event.visioUrl !== ''" class="info" size="12">
				<ion-icon name="tv-outline" style="margin-right: 5px"></ion-icon>
				<a (click)="openVisio(event.visioUrl)" target="_blank">{{ event.visioUrl }}</a>
			</ion-col>
			<ion-col size="12" style="border-bottom: solid 1px #70707040;padding-bottom: 10px;margin-bottom: 10px">
				<ion-button (click)="share()" class="bd-green bg-transparent text-green " style="height: 46px">
					<ion-icon name="share-social-outline" style="margin-right: 5px"></ion-icon>
					Partager
				</ion-button>
				<ion-button (click)="join()" [class.isJoin]="isJoin" class="bd-green bg-transparent text-green"
							style="height: 46px">
					<ion-icon *ngIf="!isJoin" name="star-outline" style="margin-right: 5px"></ion-icon>
					<ion-icon *ngIf="isJoin" name="star" style="margin-right: 5px"></ion-icon>
					Intéressé(e)
				</ion-button>
				<div *ngIf="isJoin" class="join">
					Vous recevez désormais des notifications en lien avec cet événement
				</div>
			</ion-col>
			<ion-col class="info" size="12">
				<ion-row>
					<ion-button (click)="openPopover($event)"
								style="width: 100%; --background: transparent!important;border: transparent!important;position: absolute;z-index: 999999"></ion-button>

					<ion-col *ngFor="let user of event.participants ; let  index = index; let last=last"
							 [style.display]="index >= 5?'none':'inline-block'" [style.z-index]="9999-index"
							 size="auto">
						<ion-avatar [style.margin-left]="index > 0?'-25px':'0'"
									style="border: white solid 2px;width: 42px;height: 42px;">
							<img [src]="user.picture" style=""/>
							<div *ngIf="index === 4 && last"
								 style="width: 42px;height: 42px;background: #00000056 ;border-radius: 50%;position: absolute;top: 5px; left: -20px;">
								<div style="width: 100%;height: 100%;background: #00000056 ;border-radius: 50%;">

								</div>
								<ion-icon name="ellipsis-horizontal"
										  style="font-size: 18px;color: white;position: absolute;top: 0;bottom: 0;left: 0;right: 0;margin: auto">
								</ion-icon>
							</div>
						</ion-avatar>

					</ion-col>
					<ion-col class="participants" id="participant-mobile" size="auto">{{ event.participants.length }}
						intéressé(e)<span
							*ngIf="event.participants.length > 1">s</span></ion-col>

				</ion-row>

			</ion-col>
			<ion-col size="12"
					 style="border-bottom: solid 1px #70707040;padding-bottom: 10px;margin-bottom: 10px;font-size: 14px;color: #252525!important;">
				<div [innerHTML]="event.description" style="white-space:pre-wrap;"></div>
			</ion-col>
			<ion-col size="12">
				<ion-item class="orga ion-no-margin ion-no-padding" lines="none"
						  style="--background: white!important;padding:0px !important;--padding-end: 0px;--inner-padding-end: 0px;">
					<ion-avatar (click)="openProfile(event.owner)" slot="start" style="width: 40px;height: 40px;">
						<img [src]="event.owner.picture" style=""/>
					</ion-avatar>
					<div (click)="openProfile(event.owner)">
						<span>Organisateur·trice</span><br>
						{{ event.owner.firstName }} {{ event.owner.lastName }}
					</div>
					<div (click)="openChat(event.owner)" *ngIf="this.user.uuid !== event.owner.uuid" class="chat"
						 slot="end" style="margin-right: 0;">
						<ion-icon name="chatbox-outline"></ion-icon>
					</div>
				</ion-item>
			</ion-col>
		</ion-row>
	</ion-grid>
	<ion-grid style="padding: 15px; ">
		<ion-row>
			<ion-col class="title2" size="12">
				Commentaires
			</ion-col>
			<ion-col class="sub-title" size="12">
				{{ event.comments.length }} message<span *ngIf="event.comments.length > 1">s</span>
			</ion-col>
			<ion-col *ngFor="let comment of event.comments" size="12">
				<ion-card style="padding: 10px;margin: 0;border-radius: 8px;box-shadow: none;">
					<ion-item class="comment ion-no-margin ion-no-padding" lines="none"
							  style="--background: white!important;padding:0px !important;--padding-end: 0px;--inner-padding-end: 0px;margin-right: 42px">
						<ion-avatar (click)="openProfile(comment.owner)" slot="start"
									style="width: 52px;height: 52px;margin-top: -19px">
							<img [src]="comment.owner.picture" style=""/>
						</ion-avatar>
						<div class="comment-user">
							<div [innerHTML]="comment.text | linkify:'http-link-blue'" class="comment-text"
								 style="white-space:pre-wrap;"></div>
							<br>
							{{ comment.owner.firstName }} {{ comment.owner.lastName }} | {{ comment.date }}
						</div>
					</ion-item>
					<ion-button (click)="openMenu($event,comment)"
								*ngIf="this.user.uuid === comment.owner.uuid || this.user.isSuperAdmin" class="menu"
								style="--background: white">
						<ion-icon name="ellipsis-horizontal" style="margin-left: 5px">
						</ion-icon>
					</ion-button>
				</ion-card>
			</ion-col>
		</ion-row>
	</ion-grid>

</div>
<div *ngIf="responsive.isDesktop()" style="overflow-y: auto;height: calc(100% - 143px);background: #ffffff;">
	<ion-grid
		style="padding-left: 45px;padding-right: 45px;background: white;border-bottom-left-radius:15px;border-bottom-right-radius:15px; ">
		<ion-row>
			<ion-col class="title" size="11">
				{{ event.title }}
			</ion-col>
			<ion-col size="1">
				<ion-button (click)="openMenuAll($event,event)"
							*ngIf="this.user.uuid === event.owner.uuid || this.user.isSuperAdmin" class="menu"
							style="--background: white">
					<ion-icon name="ellipsis-horizontal">
					</ion-icon>
				</ion-button>
			</ion-col>
			<ion-col size="6">
				<ion-item class="categorie ion-no-margin ion-no-padding" lines="none"
						  style="--background: white!important;">
					<div [class.ion-avatar-mobile]="responsive.isMobile()" class="ion-avatar" slot="start">
						<img [src]="'assets/image/'+event.category+'-green-icon.png'">
					</div>
					{{ eventService.categories[event.category] }}
				</ion-item>
			</ion-col>
			<ion-col class="info" size="6">
				<ion-row style="justify-content: right;">
					<ion-button (click)="openPopover($event)"
								style="width: 100%; --background: transparent!important;border: transparent!important;position: absolute;z-index: 999999"></ion-button>
					<ion-col class="participants"
							 size="auto">{{ event.participants.length }} intéressé(e)<span
						*ngIf="event.participants.length > 1">s</span></ion-col>
					<ion-col
						*ngFor="let user of event.participants ; let  index = index; let last=last"
						[style.display]="index >= 5?'none':'inline-block'" [style.z-index]="9999-index"
						size="auto">
						<ion-avatar [style.margin-left]="index > 0?'-25px':'0'"
									style="border: white solid 2px;width: 42px;height: 42px;">
							<img [src]="user.picture" style=""/>
							<div *ngIf="index === 4 && last"
								 style="width: 42px;height: 42px;background: #00000056 ;border-radius: 50%;position: absolute;top: 5px; left: -20px;">
								<div style="width: 100%;height: 100%;background: #00000056 ;border-radius: 50%;">

								</div>
								<ion-icon name="ellipsis-horizontal"
										  style="font-size: 18px;color: white;position: absolute;top: 0;bottom: 0;left: 0;right: 0;margin: auto">
								</ion-icon>
							</div>
						</ion-avatar>

					</ion-col>

				</ion-row>

			</ion-col>
			<ion-col *ngIf="event.picture!='' && event.picture !== undefined" size="12">
				<div [style.background]="'url('+event.urlPicture+')'" class="image"></div>
				<div *ngIf="isJoin" class="join-desktop">
					Vous recevez désormais des notifications en lien avec cet événement
				</div>
			</ion-col>
			<ion-col class="info" size="12">
				<ion-icon name="calendar-clear-outline"></ion-icon>
				{{ event.startAtDay }}, {{ event.startAtDayNumber }} {{ event.startAtMonth }} <span
				*ngIf="event.stopAt">- {{ event.stopAtDay }},
				{{ event.stopAtDayNumber }} {{ event.stopAtMonth }}</span>
			</ion-col>
			<ion-col class="info" size="12">
				<ion-icon name="time-outline"></ion-icon>
				{{ event.startAt | date:'HH:mm' }} <span *ngIf="event.stopAt">- {{ event.stopAt | date:'HH:mm' }}</span>
			</ion-col>
			<ion-col *ngIf="event.capacity!==''" class="info" size="12">
				<ion-icon name="people-outline"></ion-icon>
				{{ event.capacity }}
			</ion-col>
			<ion-col *ngIf="event.location !== '' && event.address !== '' && event.city !== ''&& event.zipCode !== ''"
					 class="info"
					 size="12">
				<ion-icon name="location-outline"></ion-icon>
				{{ event.location }}, {{ event.address }} {{ this.event.city }} {{ this.event.zipCode }}
			</ion-col>
			<ion-col (click)="openFile(file)" *ngFor="let file of event.files" class="info" size="12">
				<ion-icon name="attach-outline"></ion-icon>
				<span class="link">{{ file.name }}</span>
			</ion-col>
			<ion-col *ngIf="event.visioUrl !== ''" class="info" size="12">
				<ion-icon name="tv-outline" style="margin-right: 5px"></ion-icon>
				<a (click)="openVisio(event.visioUrl)" target="_blank">{{ event.visioUrl }}</a>
			</ion-col>
			<ion-col size="12" style="border-bottom: solid 1px #70707040;padding-bottom: 10px;margin-bottom: 10px">
				<ion-button (click)="share()" class="bd-green bg-transparent text-green " style="height: 46px">
					<ion-icon name="share-social-outline" style="margin-right: 5px"></ion-icon>
					Partager
				</ion-button>
				<ion-button (click)="join()" [class.isJoin]="isJoin" class="bd-green bg-transparent text-green"
							style="height: 46px">
					<ion-icon *ngIf="!isJoin" name="star-outline" style="margin-right: 5px"></ion-icon>
					<ion-icon *ngIf="isJoin" name="star" style="margin-right: 5px"></ion-icon>
					Intéressé(e)
				</ion-button>
			</ion-col>

			<ion-col size="12"
					 style="border-bottom: solid 1px #70707040;padding-bottom: 10px;margin-bottom: 10px;font-size: 14px;color: #252525!important;">
				<div [innerHTML]="event.description" style="white-space:pre-wrap;"></div>
			</ion-col>
			<ion-col size="12">
				<ion-item class="orga ion-no-margin ion-no-padding" lines="none"
						  style="--background: white!important;padding:0px !important;--padding-end: 0px;--inner-padding-end: 0px;">
					<ion-avatar (click)="openProfile(event.owner)" slot="start" style="width: 40px;height: 40px;">
						<img [src]="event.owner.picture" style=""/>
					</ion-avatar>
					<div (click)="openProfile(event.owner)">
						<span>Organisateur·trice</span><br>
						{{ event.owner.firstName }} {{ event.owner.lastName }}
					</div>
					<div (click)="openChat(event.owner)" *ngIf="this.user.uuid !== event.owner.uuid" class="chat"
						 slot="end" style="margin-right: 0;">
						<ion-icon name="chatbox-outline"></ion-icon>
					</div>
				</ion-item>
			</ion-col>
		</ion-row>
	</ion-grid>
	<ion-grid style="padding: 15px; ">
		<ion-row>
			<ion-col class="title2" size="12">
				Commentaires
			</ion-col>
			<ion-col class="sub-title" size="12">
				{{ event.comments.length }} message<span *ngIf="event.comments.length > 1">s</span>
			</ion-col>
			<ion-col *ngFor="let comment of event.comments" size="12">
				<ion-card style="padding: 10px;margin: 0;border-radius: 8px;box-shadow: none;">
					<ion-item class="comment ion-no-margin ion-no-padding" lines="none"
							  style="--background: white!important;padding:0px !important;--padding-end: 0px;--inner-padding-end: 0px;margin-right: 42px">
						<ion-avatar (click)="openProfile(comment.owner)" slot="start"
									style="width: 52px;height: 52px;margin-top: -19px">
							<img [src]="comment.owner.picture" style=""/>
						</ion-avatar>
						<div class="comment-user">
							<div [innerHTML]="comment.text | linkify:'http-link-blue'" class="comment-text"
								 style="white-space:pre-wrap;"></div>
							<br>
							{{ comment.owner.firstName }} {{ comment.owner.lastName }} | {{ comment.date }}
						</div>
					</ion-item>
					<ion-button (click)="openMenu($event,comment)"
								*ngIf="this.user.uuid === comment.owner.uuid || this.user.isSuperAdmin" class="menu"
								style="--background: white">
						<ion-icon name="ellipsis-horizontal" style="margin-left: 5px">
						</ion-icon>
					</ion-button>
				</ion-card>
			</ion-col>
		</ion-row>
	</ion-grid>

</div>
<ion-grid style="padding: 0;width: 100%">
	<ion-row class="commentaire" style="max-height: 65vh; overflow-y:auto;">
		<ion-col size="12" style="padding-bottom: 8px;">
			<ion-item lines="none">
				<ion-textarea #input2 [(ngModel)]="comment" auto-grow="true" autocapitalize="sentences"
							  autocomplete="on"
							  autocorrect="on" placeholder="Répondre..." rows="2" spellcheck="true"></ion-textarea>
				<div (click)="sendComment(input2.value)" class="send" slot="end">
					<div class="icon">
						<ion-icon name="paper-plane-outline" style="margin-bottom: 10px;"></ion-icon>
					</div>
				</div>
			</ion-item>

		</ion-col>
	</ion-row>
	<ion-row *ngIf="responsive.isMobile()" style="background: white;">
		<ion-col (click)="sendSignalement()" size="12"
				 style="text-align: center;height: 66px;line-height: 60px;text-decoration: underline;color: #F04C71;font-family: 'axiformaregular';font-size: 12px">
			Signaler cet événement
		</ion-col>
	</ion-row>
</ion-grid>
<ion-popover #popover (didDismiss)="isOpen = false" [isOpen]="isOpen" alignment="center" size="cover"
			 side="bottom" class="popover">
	<ng-template>

		<ion-content *ngIf="event.participants.length >= 1" style="--padding-top:5px; --padding-bottom: 5px; --ion-background-color: white !important;
    background: white;
    --background: white;">

			<ion-item *ngFor="let user of event.participants; let index = index; let first=first"
					  [style.z-index]="9999 - index"
					  [style]="first ? '' : 'margin-top: 15px;'"
					  lines="none">
				<ion-avatar slot="start" style="border: white solid 2px;width: 42px;height: 42px;">
					<img [src]="user.picture" style=""/>
				</ion-avatar>

				<ion-label class="name-participants">
					{{ user.firstName }} {{ user.lastName }}
				</ion-label>
			</ion-item>
		</ion-content>


		<ion-content *ngIf="event.participants.length < 1" class="ion-padding">

			<ion-row size="auto" style="justify-content: center;">
				<ion-label class="no-participants">
					Il n'y a aucun participant
				</ion-label>
			</ion-row>

		</ion-content>
	</ng-template>
</ion-popover>
