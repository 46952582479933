<ion-row *ngIf="!isList && !isDecodeur" class="row-not-list">
	<ion-col
		[style.background-image]="item.youTubeID?'url('+'https://img.youtube.com/vi/'+item.youTubeID+'/hqdefault.jpg'+')':'url('+item.image+')'"
		class="img" size="12">
		<img *ngIf="item.youTubeID" class="play" src="../../../../../assets/image/playb.png">
	</ion-col>
	<ion-col *ngIf="item.term" class="title" size="12">
		<ion-button
			[style.--background]="colors[item.term]+'!important'"
			[style.--border-color]="colors[item.term]+'!important'"
			[style.background]="colors[item.term]+'!important'"
			[style.border-color]="colors[item.term]+'!important'"
			class="button_category bd-yellow bg-yellow btn-map"
			type="button">
			{{item.term}}
		</ion-button>
	</ion-col>
	<ion-col [class]="isNotHome ? 'date-not-home' : 'date'" size="12" style="padding-bottom: 0">
		{{ item.date | date : 'dd MMMM yyyy'}}
	</ion-col>
	<ion-col class="title" size="12"
			 style="padding-top: 0;max-height: 50px;">
		<div style="overflow-y: hidden;
			 text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;
			 -webkit-box-orient: vertical;">{{item.title}}</div>
	</ion-col>
</ion-row>


<!-- DECODEUR -->
<ion-row *ngIf="isDecodeur && responsive.isMobile()" class="row-list-decodeur">
	<ion-col
		[style.background-image]="item.youTubeID?'url('+'https://img.youtube.com/vi/'+item.youTubeID+'/hqdefault.jpg'+')':'url('+item.image+')'"
		class="img" size="12">
		<img *ngIf="item.youTubeID" class="play" src="../../../../../assets/image/playb.png">
	</ion-col>
	<ion-col *ngIf="item.term" class="title" size="12">
		<ion-button
			[style.--background]="colors[item.term]+'!important'"
			[style.--border-color]="colors[item.term]+'!important'"
			[style.border-color]="colors[item.term]+'!important'"
			class="button_category bd-yellow bg-yellow btn-map"
			type="button">
			{{item.term}}
		</ion-button>
	</ion-col>
	<ion-col class="date-mobile-list" size="12" style="padding-bottom: 0">
		<!-- {{ item.date | date : 'dd MMMM yyyy'}} -->
		{{ item.date }}
	</ion-col>
	<ion-col class="title" size="12"
			 style="padding-top: 0;max-height: 50px;">
		<div style="overflow-y: hidden;
			 text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 2;
			 -webkit-box-orient: vertical;">{{item.title}}</div>
	</ion-col>
</ion-row>


<!-- MODE LISTE MOBILE -->
<ion-card *ngIf="isList && responsive.isMobile()" class="ion-card-list">
	<ion-card>
		<ion-grid style="padding-bottom: 0 !important;">
			<ion-row class="row">
				<ion-col size="8" style="padding-bottom: 0 !important;">
					<ion-item lines="none">
						<div>
							<ion-label class="titleList">
								{{item.title}}
							</ion-label>
							<p [class]="isNotHome ? 'date-not-home' : 'date'">
								{{item.dateDay}} {{item.dateMonth.substr(0, 4)}}. {{item.dateYear}}
							</p>
						</div>
					</ion-item>
				</ion-col>
				<ion-col size="4" style="padding-bottom: 0 !important;">
					<ion-card [style.background-image]="item.youTubeID?'url('+'https://img.youtube.com/vi/'+item.youTubeID+'/hqdefault.jpg'+')':'url('+item.image+')'"
							  class="imgList">
						<img *ngIf="item.youTubeID" class="play" src="../../../../../assets/image/playb.png">
					</ion-card>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-card>
</ion-card>
