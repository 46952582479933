import {environment} from '../../../../environments/environment';
import {Injectable, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable()
export class HttpService {
	host: string = environment.host;
	token = '';

	constructor(
		private router: Router,
		private zone: NgZone,
		public route: ActivatedRoute,
		private http: HttpClient
	) {
		const self = this;

		/*	if (!isDevMode()) {
				this.host = environment.host;
			}*/
	}

	// lecture
	get(action, uuid = ''): Observable<any> {
		const self = this;
		const subject = new Subject();

		self.zone.runOutsideAngular(() => {
			self.http
				.get<any>(self.url(action, uuid), {headers: self.header()})
				.subscribe(
					(result) => {
						self.success(subject, result);
					},
					(error) => {
						self.error(subject, error);
					}
				);
		});
		return subject.asObservable();
	}

	// l'ajout
	post(action, data, uuid = ''): Observable<any> {
		const self = this;
		const subject = new Subject();
		self.zone.runOutsideAngular(() => {
			self.http
				.post<any>(self.url(action, uuid), data, {
					headers: self.header(),
				})
				.subscribe(
					(result) => {
						self.success(subject, result);
					},
					(error) => {
						self.error(subject, error);
					}
				);
		});
		return subject.asObservable();
	}

	// supprime
	delete(action, uuid = ''): Observable<any> {
		const self = this;
		const subject = new Subject();
		self.zone.runOutsideAngular(() => {
			self.http
				.delete<any>(self.url(action, uuid), {headers: self.header()})
				.subscribe(
					(result) => {
						self.success(subject, result);
					},
					(error) => {
						self.error(subject, error);
					}
				);
		});
		return subject.asObservable();
	}

	// mise a jour
	patch(action, data, uuid = ''): Observable<any> {
		const self = this;
		const subject = new Subject();
		self.zone.runOutsideAngular(() => {
			self.http
				.patch<any>(self.url(action, uuid), data, {
					headers: self.header(),
				})
				.subscribe(
					(result) => {
						self.success(subject, result);
					},
					(error) => {
						self.error(subject, error);
					}
				);
		});
		return subject.asObservable();
	}

	/****************************************************************/
	url(action, uuid = '') {
		const self = this;
		let url = self.host + action;

		if (url.indexOf('?') === -1) {
			url += '?';
		} else {
			url += '&';
		}
		// url += '&';

		// url += 'cache=' + new Date().getTime();


		if (uuid !== '') {
			url =
				self.host +
				action +
				'/' +
				uuid 
				// +
				// '?cache:' +
				// new Date().getTime();
		}
		return url;
	}

	header() {
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + this.token,
		});

		return headers;
	}

	success(subject, result) {
		const self = this;
		self.zone.run(() => subject.next(result));
		subject.complete();
	}

	error(subject, error) {
		const self = this;
		console.error(error.message);
		switch (error.status) {
			case 0:
				self.zone.run(() => subject.error(error.message));
				break;
			case 401:
				localStorage.removeItem('token');
				localStorage.removeItem('user');
				self.router.navigate(['/auth/signin']);
				break;

			case 404:
				self.zone.run(() => subject.error(error.message));
				break;
			case 413:
				self.zone.run(() => subject.error(error.message));
				break;
			default:
				console.error(error.error.message);
				self.zone.run(() => subject.error(error.error.message));
				break;
		}
		subject.complete();
	}
}
