import {Injectable} from "@angular/core";
import {Platform} from "@ionic/angular";
import {Router} from "@angular/router";
import {BehaviorSubject} from "rxjs";

@Injectable({
	providedIn: "root",
})
export class ResponsiveService {
	width = 1024;


	state: BehaviorSubject<string>;

	constructor(private platform: Platform, private router: Router) {
		this.state = new BehaviorSubject<string>("mobile");
		window.addEventListener("resize", () => {
			if (this.platform.width() <= this.width) {
				this.state.next("mobile");
			}
			if (this.platform.width() > this.width) {
				this.state.next("desktop");
			}
		});
	}

	isMobile(): boolean {
		//  this.router.navigate([this.router.url+'/map'])
		return this.platform.width() <= this.width;
	}

	isDesktop(): boolean {

		return this.platform.width() > this.width;
	}

}
