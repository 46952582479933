<ion-content>
	<ion-icon (click)="dismissModal()" class="close" name="close"></ion-icon>
	<ion-grid>
		<ion-row>
			<ion-col size="12">
				<div class="title-modal">Notifications</div>
				<div class="sub-title-modal">Acceptez les notifications pour ne rien rater de CFTC l'App</div>
			</ion-col>
			<ion-col [formGroup]="notifForm" class="notif" size="12">
				<ion-item lines="none">
					<ion-label class="ion-text-wrap" slot="start">
						<div class="title">
							<ion-icon name="notifications-outline"></ion-icon>
							Notifications
						</div>
						<div class="description ">
							Autorisez CFTC l’App à vous envoyer des notifications pour ne rien rater !
						</div>
					</ion-label>
					<ion-toggle (click)="changeAll($event)" formControlName="notificationEnabled" mode="ios"
								slot="end"></ion-toggle>
				</ion-item>
				<ion-item class="border" lines="none">
					<ion-label class="ion-text-wrap" slot="start">
						<div class="title-sub">
							Forum
						</div>
						<div class="description-sub">
							Soyez notifié lorsque des commentaires sont publiés sur vos sujets du forum ou sur ceux que
							vous avez commentés.
						</div>
					</ion-label>
					<ion-toggle
						formControlName="forum"
						mode="ios"
						slot="end"></ion-toggle>
				</ion-item>
				<ion-item class="border" lines="none">
					<ion-label class="ion-text-wrap" slot="start">
						<div class="title-sub">
							Petites annonces
						</div>
						<div class="description-sub">
							Soyez notifié lorsque des commentaires sont publiés sur vos petites annonces ou sur celles
							que vous avez commentées.
						</div>
					</ion-label>
					<ion-toggle
						formControlName="offer"
						mode="ios"
						slot="end"></ion-toggle>
				</ion-item>
				<ion-item class="border" lines="none">
					<ion-label class="ion-text-wrap" slot="start">
						<div class="title-sub">
							Messages
						</div>
						<div class="description-sub">
							Soyez notifié lorsque vous recevez un message.
						</div>
					</ion-label>
					<ion-toggle
						formControlName="message"
						mode="ios"
						slot="end"></ion-toggle>
				</ion-item>
				<ion-item lines="none" style="padding-top: 15px">
					<ion-label class="ion-text-wrap" slot="start">
						<div class="title-sub">
							Evenements
						</div>
						<div class="description-sub">
							Soyez notifié lorsque des commentaires sont publiés sur vos événements ou sur ceux que vous
							avez commentés.
						</div>
					</ion-label>
					<ion-toggle
						formControlName="event"
						mode="ios"
						slot="end"></ion-toggle>
				</ion-item>

			</ion-col>
			<ion-col size="12">
				<ion-button (click)="changeNotification()" class="bd-yellow bg-yellow text-white" style="width: 100%">
					Continuer
				</ion-button>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>
