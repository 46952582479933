<ion-item (click)="dismissModal()" lines="none"
		  style="--background: white!important;--min-height: 66px;--max-height: 66px">
	<ion-icon *ngIf="responsive.isMobile()" class="back-icon" name="chevron-back-outline" slot="start"></ion-icon>
	<ion-label class="back">Événements</ion-label>
	<ion-icon (click)="dismissModal()" *ngIf="responsive.isDesktop()" class="back-icon" name="close-outline"
			  slot="end"></ion-icon>
</ion-item>
<ion-content *ngIf="responsive.isMobile()" style="--background: white;">
	<ion-grid style="padding: 15px;">
		<ion-row>
			<ion-col *ngIf="event.uuid === ''" class="title" size="12">
				Créer un événement
			</ion-col>
			<ion-col *ngIf="event.uuid !== ''" class="title" size="12">
				Modifier un événement
			</ion-col>
			<ion-col class="sub-title" size="12">
				Informations sur l’événement
			</ion-col>
			<ion-col size="12">
				<ion-item class="input" lines="none">
					<ion-select (ionChange)="categorieChange($event)" [(ngModel)]="event.category"
								[disabled]="category!==''"
								[interfaceOptions]="{'cssClass': 'mycomponent-wider-popover'}" interface="popover"
								mode="ios"
								placeholder="Catégorie d’événement*">
						<ion-select-option *ngFor="let category of categories"
										   [value]="category.key">{{ category.title }}
						</ion-select-option>
					</ion-select>
					<ion-icon name="chevron-down-outline" style="position: absolute; right:10px;"></ion-icon>
				</ion-item>
			</ion-col>

			<ion-col size="12">
				<ion-input [(ngModel)]="event.title" autocapitalize="sentences" autocomplete="on" autocorrect="on"
						   placeholder="Nom de l'événement*" spellcheck="true"></ion-input>
			</ion-col>
			<ion-col size="12">
				<ion-item class="picture ion-no-padding" lines="none">
					<ion-label class="allDay">Toute la journée</ion-label>
					<ion-toggle [(ngModel)]="isAllDay"></ion-toggle>
				</ion-item>
			</ion-col>
			<ion-col *ngIf="!isAllDay" size="6">
				<ion-item button="true" class="input" id="open-date-input-startAt-mobile"
						  lines="none">
					<ion-label *ngIf="event.startAt === ''">Date de début*</ion-label>
					<ion-text>{{ event.startAt | date:'dd/MM/YYYY' }}</ion-text>
					<ion-popover show-backdrop="false" trigger="open-date-input-startAt-mobile">
						<ng-template>
							<ion-datetime (ionChange)="changeStartAtDate($event)" [(ngModel)]="event.startAt"
										  [isDateEnabled]="dayAlreadyGone" cancelText="Annuler" doneText="Valider"
										  max="3000"
										  min="2024"
										  mode="md"
										  presentation="date">
								<span slot="time-label">Heure</span>
							</ion-datetime>
						</ng-template>
					</ion-popover>
					<ion-icon name="calendar-clear-outline" slot="end"></ion-icon>
				</ion-item>
			</ion-col>
			<ion-col *ngIf="!isAllDay" size="6" style="padding-left: 10px!important;">
				<ion-item button="true" class="input" id="open-time-input-startAt-mobile"
						  lines="none">
					<ion-label *ngIf="event.startAt === ''">Heure de début*</ion-label>
					<ion-text>{{ event.startAt | date:'HH:mm' }}</ion-text>
					<ion-popover show-backdrop="false" trigger="open-time-input-startAt-mobile">
						<ng-template>
							<ion-datetime (ionChange)="changeStartAtTime($event)" [(ngModel)]="event.startAt"
										  [isDateEnabled]="dayAlreadyGone" cancelText="Annuler"
										  doneText="Valider"
										  max="3000"
										  min="2024"
										  mode="md"
										  presentation="time">
								<span slot="time-label">Heure</span>
							</ion-datetime>
						</ng-template>
					</ion-popover>
					<ion-icon name="calendar-clear-outline" slot="end"></ion-icon>
				</ion-item>
			</ion-col>
			<ion-col *ngIf="isAllDay" size="12">
				<!-- Si case toute la journée est active -->
				<ion-item button="true" class="input" id="open-date-input-startAt-allDay-desktop"
						  lines="none">
					<ion-label *ngIf="event.startAt === ''">Date de début*</ion-label>
					<ion-text>{{ event.startAt | date:'dd/MM/YYYY' }}</ion-text>

					<ion-popover show-backdrop="false" trigger="open-date-input-startAt-allDay-desktop">
						<ng-template>
							<ion-datetime [(ngModel)]="event.startAt"
										  [isDateEnabled]="dayAlreadyGone"
										  cancelText="Annuler"
										  doneText="Valider" max="3000"
										  min="2024"
										  mode="md"
										  presentation="date">
							</ion-datetime>
						</ng-template>
					</ion-popover>
					<ion-icon name="calendar-clear-outline" slot="end"></ion-icon>
				</ion-item>
			</ion-col>

			<ion-col *ngIf="!isAllDay && dateEnd" size="6">
				<ion-item button="true" class="input"
						  id="open-date-input-stopAt-mobile" lines="none">

					<ion-label *ngIf="event.stopAt === ''">Date de fin</ion-label>
					<ion-text>{{ event.stopAt | date:'dd/MM/YYYY' }}</ion-text>
					<ion-popover show-backdrop="false" trigger="open-date-input-stopAt-mobile">
						<ng-template>
							<ion-datetime (ionChange)="changeStopAtDate($event)" [(ngModel)]="event.stopAt"
										  [isDateEnabled]="dayAlreadyGoneFinish"
										  cancelText="Annuler" doneText="Valider"
										  max="3000"
										  min="2024"
										  presentation="date">
								<span slot="time-label">Heure</span>
							</ion-datetime>
						</ng-template>
					</ion-popover>
					<ion-icon name="calendar-clear-outline" slot="end"></ion-icon>
				</ion-item>
			</ion-col>
			<ion-col *ngIf="!isAllDay && dateEnd" size="6" style="padding-left: 10px!important;">
				<ion-item button="true" class="input"
						  id="open-time-input-stopAt-mobile" lines="none">

					<ion-label *ngIf="event.stopAt === ''">Heure de fin</ion-label>
					<ion-text>{{ event.stopAt | date:'HH:mm' }}</ion-text>
					<ion-popover show-backdrop="false" trigger="open-time-input-stopAt-mobile">
						<ng-template>
							<ion-datetime (ionChange)="changeStartAtTime($event)" [(ngModel)]="event.stopAt"
										  [isDateEnabled]="dayAlreadyGoneFinish"
										  cancelText="Annuler" doneText="Valider"
										  max="3000"
										  min="2024"
										  presentation="time">
								<span slot="time-label">Heure</span>
							</ion-datetime>
						</ng-template>
					</ion-popover>
					<ion-icon name="calendar-clear-outline" slot="end"></ion-icon>
				</ion-item>
			</ion-col>

			<ion-col *ngIf="!isAllDay && !dateEnd" size="12">
				<ion-label (click)="addDate()" class="add-date">Ajouter une date
					et
					une heure de fin
				</ion-label>
			</ion-col>
			<ion-col size="12">
				<ion-input [(ngModel)]="event.location" autocapitalize="sentences" autocomplete="on" autocorrect="on"
						   placeholder="Nom du lieu" spellcheck="true"></ion-input>
			</ion-col>
			<ion-col size="12">
				<ion-input [(ngModel)]="event.address" autocapitalize="sentences" autocomplete="on" autocorrect="on"
						   placeholder="Adresse complète" spellcheck="true"></ion-input>
			</ion-col>
			<ion-col size="12">
				<ion-input [(ngModel)]="event.city" autocapitalize="sentences" autocomplete="on" autocorrect="on"
						   placeholder="Ville" spellcheck="true"></ion-input>
			</ion-col>
			<ion-col size="12">
				<ion-input [(ngModel)]="event.zipCode" autocapitalize="sentences" autocomplete="on" autocorrect="on"
						   placeholder="Code postal" spellcheck="true"></ion-input>
			</ion-col>
			<ion-col size="12">
				<ion-input [(ngModel)]="event.capacity" autocapitalize="sentences" autocomplete="on" autocorrect="on"
						   placeholder="Capacité d’accueil" spellcheck="true"></ion-input>
			</ion-col>
			<ion-col class="border-botton" size="12">
				<ion-textarea #myTextArea [(ngModel)]="event.description" autocapitalize="sentences" autocomplete="on"
							  autocorrect="on" placeholder="Description" rows="5" spellcheck="true"
							  type="text">
				</ion-textarea>
			</ion-col>
			<ion-col class="sub-title" size="12" style="margin-bottom: 10px!important">
				Photo de l’événement
			</ion-col>
			<ion-col class="border-botton" size="12">
				<ion-item class="picture ion-no-padding" lines="none">
					<ion-label slot="start">Télécharger une photo</ion-label>
					<ion-button (click)="picture()" class="bg-yellow bd-yellow text-white" slot="end">
						<ion-icon name="images-outline" slot="start"></ion-icon>
						Télécharger
					</ion-button>
				</ion-item>
				<ion-item *ngIf="event.picture!==''" class="files ion-no-padding" lines="none"
						  style="--background: transparent!important;">
					<ion-icon name="attach-outline"></ion-icon>
					<ion-label>Une image téléchargée</ion-label>
					<ion-icon (click)="removeImage()" name="close-outline" style="margin-left: 10px"></ion-icon>
				</ion-item>
			</ion-col>
			<ion-col class="sub-title" size="12">
				<ion-label>Joindre un / plusieurs fichiers</ion-label>
			</ion-col>
			<ion-col size="12" style="margin-bottom: 5px !important;">
				<ion-item class="file ion-no-padding" lines="none">
					<ion-label class="ion-text-wrap" fixed slot="start">Formats .jpg, .pdf, .png (max. 10Mo / fichier)
					</ion-label>
					<input #fileInput (change)="chooseFile($event)" accept=".jpg,.png,.pdf" style="display:none;"
						   type="file">
					<ion-button (click)="fileInput.click()" slot="end">
						<ion-icon name="push-outline"></ion-icon>
					</ion-button>
				</ion-item>
			</ion-col>
			<ion-col *ngIf="event && event.files.length>0" size="12">
				<ion-item *ngFor="let file of event.files;let index=index" class="files ion-no-padding"
						  lines="none" style="--background: transparent!important">
					<ion-icon name="attach-outline"></ion-icon>
					<ion-label>{{ file.name }}</ion-label>
					<ion-icon (click)="removeFile(index)" name="close-outline" style="margin-right: 10px"></ion-icon>
				</ion-item>
			</ion-col>
			<ion-col class="sub-title" size="12" style="margin-bottom: 0px!important">
				événement en ligne
			</ion-col>
			<ion-col size="12" style="margin-bottom: 0px!important">
				<ion-item class="picture ion-no-padding" lines="none">
					<ion-label>L’événement sera en visioconférence</ion-label>
					<ion-toggle [(ngModel)]="isVisio"></ion-toggle>
				</ion-item>
			</ion-col>
			<ion-col class="border-botton" size="12">
				<ion-input [(ngModel)]="event.visioUrl" [disabled]="!isVisio" autocapitalize="sentences"
						   autocomplete="on"
						   autocorrect="on" placeholder="Lien de la visioconférence"
						   spellcheck="true"></ion-input>
			</ion-col>
			<ion-col class="sub-title" size="12" style="margin-bottom: 0px!important">
				Messagerie
			</ion-col>
			<ion-col class="border-botton" size="12" style="margin-bottom: 0!important;padding-bottom: 0!important">
				<ion-item class="picture ion-no-padding" lines="none">
					<p>
						J’accepte être contacté en direct<br>
						par messagerie par les adhérents
					</p>
					<ion-toggle [(ngModel)]="event.canMessaging" slot="end"></ion-toggle>
				</ion-item>
			</ion-col>
			<ion-col class="sub-title" size="12" style="margin-bottom: 0px!important;margin-top: 15px!important;">
				Visibilité
			</ion-col>
			<ion-col class="border-botton" size="12" style="padding-bottom: 0!important">
				<ion-item class="picture ion-no-padding" lines="none">
					<p>
						Rendre l’événement privé<br>
						(Accessible uniquement pour les adhérents)
					</p>
					<ion-toggle [(ngModel)]="event.isPrivate" slot="end"></ion-toggle>
				</ion-item>
			</ion-col>
			<ion-col *ngIf="user.isSuperAdmin" class="sub-title" size="12" style="margin-bottom: 0px!important;">
				National
			</ion-col>
			<ion-col *ngIf="user.isSuperAdmin" class="border-botton" size="12" style="padding-bottom: 0!important">
				<ion-item class="picture ion-no-padding" lines="none">
					<p>
						Rendre l’événement national
					</p>
					<ion-toggle [(ngModel)]="event.national" slot="end"></ion-toggle>
				</ion-item>
			</ion-col>
			<ion-col size="12">
				<ion-item class="picture ion-no-padding check" lines="none">
					<ion-label>J’accepte les conditions générales.*</ion-label>
					<ion-checkbox [(ngModel)]="cg" mode="md" slot="start"></ion-checkbox>
				</ion-item>
			</ion-col>
			<ion-col size="12">
				<ion-button (click)="edit()" *ngIf="event.uuid !== ''"
							[disabled]="event.title === '' || event.startAt ==='' || !cg"
							class="bg-yellow bd-yellow text-white" style="width: 100%;margin: 0">
					Modifier l’événement
				</ion-button>
				<ion-button (click)="save()" *ngIf="event.uuid === ''"
							[disabled]="event.title === '' || event.startAt ==='' || !cg"
							class="bg-yellow bd-yellow text-white" style="width: 100%;margin: 0">
					Créer l’événement
				</ion-button>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>
<ion-content *ngIf="responsive.isDesktop()" class="ion-content-all" style="--background: white;">
	<ion-grid style="padding: 0 !important;">
		<ion-row>
			<ion-col *ngIf="event.uuid === ''" size="12">
				<ion-item class="title-item" lines="none">
					<ion-img class="title-icon"
							 src="assets/image/menu-12.png"
							 style="width: 26px;margin-left: 0"></ion-img>
					<ion-label>Créer un événement</ion-label>
				</ion-item>
			</ion-col>
			<ion-col *ngIf="event.uuid !== ''" class="title" size="12">
				Modifier un événement
			</ion-col>
			<!--ion-col class="sub-title" size="12">
				Informations sur l’événement
			</ion-col-->
			<ion-col size="6" style="padding-right: 30px!important;">
				<ion-row>

					<ion-col size="12">
						<ion-item class="input" lines="none">
							<ion-select (ionChange)="categorieChange($event)" [(ngModel)]="event.category"
										[disabled]="category!==''"
										[interfaceOptions]="{'cssClass': 'mycomponent-wider-popover'}"
										interface="popover" mode="ios"
										placeholder="Catégorie d’événement*">
								<ion-select-option *ngFor="let category of categories"
												   [value]="category.key">{{ category.title }}
								</ion-select-option>
							</ion-select>
							<ion-icon name="chevron-down-outline" style="position: absolute; right: 10px;"></ion-icon>
						</ion-item>
					</ion-col>

					<ion-col size="12">
						<ion-input [(ngModel)]="event.title" autocapitalize="sentences" autocomplete="on"
								   autocorrect="on"
								   placeholder="Nom de l'événement*" spellcheck="true"></ion-input>
					</ion-col>
					<ion-col size="12">

						<ion-item class="picture ion-no-padding" lines="none">
							<ion-label class="allDay">Toute la journée</ion-label>
							<ion-toggle [(ngModel)]="isAllDay"></ion-toggle>
						</ion-item>
					</ion-col>
					<ion-col *ngIf="!isAllDay" size="6">
						<ion-item button="true" class="input" id="open-date-input-startAt"
								  lines="none">
							<ion-label *ngIf="event.startAt === ''">Date de début*</ion-label>
							<ion-text>{{ event.startAt | date:'dd/MM/YYYY' }}</ion-text>
							<ion-popover show-backdrop="false" trigger="open-date-input-startAt">
								<ng-template>
									<ion-datetime (ionChange)="changeStartAtDate($event)" [(ngModel)]="event.startAt"
												  [isDateEnabled]="dayAlreadyGone"
												  max="3000"
												  min="2024"
												  presentation="date">
									</ion-datetime>
								</ng-template>
							</ion-popover>
							<ion-icon name="calendar-clear-outline" slot="end"></ion-icon>
						</ion-item>
					</ion-col>
					<ion-col *ngIf="!isAllDay" size="6" style="padding-left:10px!important">
						<ion-item button="true" class="input" id="open-time-input-startAt"
								  lines="none">
							<ion-label *ngIf="event.startAt === ''">Heure de début*</ion-label>
							<ion-text>{{ event.startAt | date:'HH:mm' }}</ion-text>
							<ion-popover show-backdrop="false" trigger="open-time-input-startAt">
								<ng-template>
									<ion-datetime (ionChange)="changeStartAtTime($event)" [(ngModel)]="event.startAt"
												  [isDateEnabled]="dayAlreadyGone"
												  max="3000"
												  min="2024"
												  presentation="time">
									</ion-datetime>
								</ng-template>
							</ion-popover>
							<ion-icon name="calendar-clear-outline" slot="end"></ion-icon>
						</ion-item>
					</ion-col>
					<!-- Si case toute la journée est active -->
					<ion-col *ngIf="isAllDay" size="12">
						<ion-item button="true" class="input"
								  id="open-date-input-startAt-allDay-mobile"
								  lines="none">
							<ion-label *ngIf="event.startAt === ''">Date de début*</ion-label>
							<ion-text>{{ event.startAt | date:'dd/MM/YYYY' }}</ion-text>

							<ion-popover show-backdrop="false" trigger="open-date-input-startAt-allDay-mobile">
								<ng-template>
									<ion-datetime [(ngModel)]="event.startAt" [isDateEnabled]="dayAlreadyGone"
												  cancelText="Annuler"
												  doneText="Valider" max="3000"
												  min="2024"
												  mode="md"
												  presentation="date">
									</ion-datetime>
								</ng-template>
							</ion-popover>
							<ion-icon name="calendar-clear-outline" slot="end"></ion-icon>
						</ion-item>
					</ion-col>
					<ion-col *ngIf="!isAllDay && dateEnd " size="6">
						<ion-item button="true" class="input"
								  id="open-date-input-stopAt" lines="none">
							<ion-label *ngIf="event.stopAt === ''">Date de fin</ion-label>
							<ion-text>{{ event.stopAt | date:'dd/MM/YYYY' }}</ion-text>
							<ion-popover show-backdrop="false" trigger="open-date-input-stopAt">
								<ng-template>
									<ion-datetime (ionChange)="changeStopAtDate($event)" [(ngModel)]="event.stopAt"
												  [isDateEnabled]="dayAlreadyGoneFinish" cancelText="Annuler"
												  doneText="Valider"
												  max="3000"
												  min="2024"
												  presentation="date">
									</ion-datetime>
								</ng-template>
							</ion-popover>
							<ion-icon name="calendar-clear-outline" slot="end"></ion-icon>
						</ion-item>
					</ion-col>
					<ion-col *ngIf="!isAllDay && dateEnd" size="6" style="padding-left:10px!important">
						<ion-item button="true" class="input" id="open-time-input-stopAt" lines="none">
							<ion-label *ngIf="event.stopAt === ''">Heure de fin</ion-label>
							<ion-text>{{ event.stopAt | date:'HH:mm' }}</ion-text>
							<ion-popover show-backdrop="false" trigger="open-time-input-stopAt">
								<ng-template>
									<ion-datetime (ionChange)="changeStopAtTime($event)" [(ngModel)]="event.stopAt"
												  [isDateEnabled]="dayAlreadyGone" cancelText="Annuler"
												  doneText="Valider"
												  max="3000"
												  min="2024"
												  presentation="time">
									</ion-datetime>
								</ng-template>
							</ion-popover>
							<ion-icon name="calendar-clear-outline" slot="end"></ion-icon>
						</ion-item>
					</ion-col>
					<ion-col *ngIf="!isAllDay && !dateEnd" size="12">

						<ion-label (click)="addDate()" class="add-date">Ajouter
							une date
							et
							une heure de fin
						</ion-label>
					</ion-col>
					<ion-col size="12">
						<ion-input [(ngModel)]="event.location" autocapitalize="sentences" autocomplete="on"
								   autocorrect="on"
								   placeholder="Nom du lieu" spellcheck="true"></ion-input>
					</ion-col>
					<ion-col size="12">
						<ion-input [(ngModel)]="event.address" autocapitalize="sentences" autocomplete="on"
								   autocorrect="on"
								   placeholder="Adresse complète" spellcheck="true"></ion-input>
					</ion-col>
					<ion-col size="12">
						<ion-input [(ngModel)]="event.city" autocapitalize="sentences" autocomplete="on"
								   autocorrect="on"
								   placeholder="Ville" spellcheck="true"></ion-input>
					</ion-col>
					<ion-col size="12">
						<ion-input [(ngModel)]="event.zipCode" autocapitalize="sentences" autocomplete="on"
								   autocorrect="on"
								   placeholder="Code postal" spellcheck="true"></ion-input>
					</ion-col>
					<ion-col size="12">
						<ion-input [(ngModel)]="event.capacity" autocapitalize="sentences" autocomplete="on"
								   autocorrect="on"
								   placeholder="Capacité d’accueil" spellcheck="true"></ion-input>
					</ion-col>
					<ion-col size="12">
						<ion-textarea #myTextArea [(ngModel)]="event.description" autocapitalize="sentences"
									  autocomplete="on"
									  autocorrect="on" placeholder="Description" rows="5" spellcheck="true"
									  type="text">
						</ion-textarea>
					</ion-col>
				</ion-row>
			</ion-col>
			<ion-col size="6" style="padding-left: 30px!important;">
				<ion-row>
					<ion-col class="sub-title" size="12" style="margin-bottom: 10px!important">
						Photo de l’événement
					</ion-col>
					<ion-col class="border-botton" size="12">
						<ion-item class="picture ion-no-padding" lines="none">
							<ion-label slot="start">Télécharger une photo</ion-label>
							<ion-button (click)="picture()" class="bg-yellow bd-yellow text-white" slot="end">
								<ion-icon name="images-outline" slot="start"></ion-icon>
								Télécharger
							</ion-button>
						</ion-item>
						<ion-item *ngIf="event.picture!==''" class="files ion-no-padding" lines="none"
								  style="--background: transparent!important;">
							<ion-icon name="attach-outline"></ion-icon>
							<ion-label>Une image téléchargée</ion-label>
							<ion-icon (click)="removeImage()" name="close-outline" style="margin-left: 10px"></ion-icon>
						</ion-item>
					</ion-col>
					<ion-col class="sub-title" size="12">
						<ion-label>Joindre un / plusieurs fichiers</ion-label>
					</ion-col>
					<ion-col [class.border-botton]="event && event.files.length===0" size="12"
							 style="margin-bottom: 5px !important;">
						<ion-item class="file ion-no-padding" lines="none">
							<ion-label class="ion-text-wrap" fixed slot="start">Formats .jpg, .pdf, .png (max. 10Mo /
								fichier)
							</ion-label>
							<input #fileInput (change)="chooseFile($event)" accept=".jpg,.png,.pdf"
								   style="display:none;"
								   type="file">
							<ion-button (click)="fileInput.click()" slot="end">
								<ion-icon name="push-outline"></ion-icon>
							</ion-button>
						</ion-item>
					</ion-col>
					<ion-col *ngIf="event && event.files.length>0" class="border-botton" size="12">
						<ion-item *ngFor="let file of event.files;let index=index" class="files ion-no-padding"
								  lines="none" style="--background: transparent!important">
							<ion-icon name="attach-outline"></ion-icon>
							<ion-label>{{ file.name }}</ion-label>
							<ion-icon (click)="removeFile(index)" name="close-outline"
									  style="margin-right: 10px"></ion-icon>
						</ion-item>
					</ion-col>
					<ion-col class="sub-title" size="12" style="margin-bottom: 0px!important">
						événement en ligne
					</ion-col>
					<ion-col size="12" style="margin-bottom: 0px!important">
						<ion-item class="picture ion-no-padding" lines="none">
							<ion-label>L’événement sera en visioconférence</ion-label>
							<ion-toggle [(ngModel)]="isVisio"></ion-toggle>
						</ion-item>
					</ion-col>
					<ion-col class="border-botton" size="12">
						<ion-input [(ngModel)]="event.visioUrl" [disabled]="!isVisio" autocapitalize="sentences"
								   autocomplete="on"
								   autocorrect="on" placeholder="Lien de la visioconférence"
								   spellcheck="true"></ion-input>
					</ion-col>
					<ion-col class="sub-title" size="12" style="margin-bottom: 0px!important">
						Messagerie
					</ion-col>
					<ion-col class="border-botton" size="12"
							 style="margin-bottom: 0!important;padding-bottom: 0!important">
						<ion-item class="picture ion-no-padding" lines="none">
							<p>
								J’accepte être contacté en direct<br>
								par messagerie par les adhérents
							</p>
							<ion-toggle [(ngModel)]="event.canMessaging" slot="end"></ion-toggle>
						</ion-item>
					</ion-col>
					<ion-col class="sub-title" size="12"
							 style="margin-bottom: 0px!important;margin-top: 15px!important;">
						Visibilité
					</ion-col>
					<ion-col class="border-botton" size="12" style="padding-bottom: 0!important">
						<ion-item class="picture ion-no-padding" lines="none">
							<p>
								Rendre l’événement privé<br>
								(Accessible uniquement pour les adhérents)
							</p>
							<ion-toggle [(ngModel)]="event.isPrivate" slot="end"></ion-toggle>
						</ion-item>
					</ion-col>
					<ion-col *ngIf="user.isSuperAdmin" class="sub-title" size="12"
							 style="margin-bottom: 0px!important;">
						National
					</ion-col>
					<ion-col *ngIf="user.isSuperAdmin" class="border-botton" size="12"
							 style="padding-bottom: 0!important">
						<ion-item class="picture ion-no-padding" lines="none">
							<p>
								Rendre l’événement national
							</p>
							<ion-toggle [(ngModel)]="event.national" slot="end"></ion-toggle>
						</ion-item>
					</ion-col>
					<ion-col size="12">
						<ion-item class="picture ion-no-padding check" lines="none">
							<ion-label>J’accepte les conditions générales.*</ion-label>
							<ion-checkbox [(ngModel)]="cg" mode="md" slot="start"></ion-checkbox>
						</ion-item>
					</ion-col>
					<ion-col size="12">
						<ion-button (click)="edit()" *ngIf="event.uuid !== ''"
									[disabled]="event.title === '' || event.startAt ==='' || !cg "
									class="bg-yellow bd-yellow text-white" style="width: 100%;margin: 0">
							Modifier l’événement
						</ion-button>
						<ion-button (click)="save()" *ngIf="event.uuid === ''"
									[disabled]="event.title === '' || event.startAt ==='' || !cg "
									class="bg-yellow bd-yellow text-white" style="width: 100%;margin: 0">
							Créer l’événement
						</ion-button>
					</ion-col>
				</ion-row>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-content>
