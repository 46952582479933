import {Component, OnDestroy, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ResponsiveService} from '../../../shared/services/utils/responsive.service';
import {Router} from '@angular/router';
import {
	AbstractControl,
	UntypedFormBuilder,
	UntypedFormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import {PasswordValidator} from '../../../shared/directive/password.validator';
import {UserService} from '../../../shared/services/user.service';
import {ToastService} from '../../../shared/services/utils/toast.service';
import {User} from '../../../shared/models/user';
import {ModalService} from 'src/app/shared/services/utils/modal.service';
import {SubscriptionService} from '../../../shared/services/subscription.service';
import {SubscriptionPaper} from '../../../shared/models/subscriptionPaper';

@Component({
	selector: 'app-modal-setting',
	templateUrl: './setting.component.html',
	styleUrls: ['./setting.component.scss'],

})
export class SettingModalComponent implements OnInit, OnDestroy {

	authForm: UntypedFormGroup;
	cookForm: UntypedFormGroup;
	notifForm: UntypedFormGroup;
	subNewsForm: UntypedFormGroup;
	showPassword = false;
	showConfirmPassword = false;
	user: User = this.userService.getUserAuthValue();
	firstChangeNotification = true;
	firstChangeCookie = true;


	subsPaper: SubscriptionPaper[] = [];
	subsPaper$: any;
	subsNews$: any;

	constructor(
		public modalController: ModalController,
		public responsive: ResponsiveService,
		public userService: UserService,
		public toastService: ToastService,
		private formBuilder: UntypedFormBuilder,
		private router: Router,
		public modalService: ModalService,
		private subService: SubscriptionService,
		//private pixel: PixelService,
	) {

	}


	ngOnInit(): void {
		this.init();
	}

	ionViewWillEnter() {
		this.init();
	}

	init() {
		this.authForm = this.formBuilder.group({
			password: ['', [Validators.required, Validators.min(8), PasswordValidator.strong]],
			confirmPassword: ['', Validators.required]
		}, {validators: PasswordValidator.checkPasswords});

		this.notifForm = this.formBuilder.group({
			forum: [this.user.notificationOptions.forum],
			offer: [this.user.notificationOptions.offer],
			message: [this.user.notificationOptions.message],
			event: [this.user.notificationOptions.event],
			notificationEnabled: [this.user.notificationEnabled]
		});
		this.cookForm = this.formBuilder.group({
			cookieEnabled: [this.user.cookieEnabled],
			analytics: [this.user.cookieOptions.analytics],
			facebook: [this.user.cookieOptions.facebook],
		});
		this.subNewsForm = this.formBuilder.group({
			cftc_app: [true],
			cftc_bi_mensuelle: [true],
		});
		this.subsPaper$ = this.subService.getPapers(this.user.inaric).subscribe((subs: any[]) => {
			this.subsPaper = subs;
		});
		this.subsNews$ = this.subService.getNews().subscribe((subs: any[]) => {
			if (subs) {
				// tslint:disable-next-line:forin
				for (const subsKey in subs) {
					this.subNewsForm.get(subsKey)?.setValue(subs[subsKey]);
				}
			}
		});
	}

	ngOnDestroy() {
		if (this.subsPaper$) {
			this.subsPaper$.unsubscribe();
		}
		if (this.subsNews$) {
			this.subsNews$.unsubscribe();
		}
	}

	checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
		const pass = group.get('password').value;
		const confirmPass = group.get('confirmPassword').value;
		return pass === confirmPass ? null : {notSame: true};
	}

	changeVisibility(event) {
		let directoryVisible = 0;
		if (!event.target.checked) {
			directoryVisible = 1;
		}
		this.userService.updateVisibility(directoryVisible).subscribe(value => {

			},
			error => {
				this.toastService.show(error);
			});
	}

	changeNotificationOptions(event, type: string) {


		this.notifForm.get(type)?.setValue(!event.target.checked);


		const notificationOptions = {
			forum: this.notifForm.get('forum').value,
			offer: this.notifForm.get('offer').value,
			message: this.notifForm.get('message').value,
			event: this.notifForm.get('event').value,
		};

		this.userService.changeNotificationOptions(notificationOptions).subscribe(data => {
			this.user.notificationOptions = data.notificationOptions;
			this.notifForm.get('forum')?.setValue(this.user.notificationOptions.forum);
			this.notifForm.get('offer')?.setValue(this.user.notificationOptions.offer);
			this.notifForm.get('message')?.setValue(this.user.notificationOptions.message);
			this.notifForm.get('event')?.setValue(this.user.notificationOptions.event);
		});
	}

	changeCookieOptions(event, type) {
		switch (type) {
			case 'analytics':
				this.userService.changeAnalytics(!event.target.checked).subscribe(data => {
					this.user.cookieOptions = data.cookieOptions;
					this.cookForm.get('analytics')?.setValue(this.user.cookieOptions.analytics);
				}, error => {
					this.toastService.show(error);
				});
				break;
			case 'facebook':
				this.userService.changeFacebook(!event.target.checked).subscribe(data => {
					this.user.cookieOptions = data.cookieOptions;
					this.cookForm.get('facebook')?.setValue(this.user.cookieOptions.facebook);
					if (this.user.cookieOptions.facebook) {
						//this.pixel.initialize();
					} else {
						//this.pixel.remove();
					}
				}, error => {
					this.toastService.show(error);
				});
				break;
		}
	}

	changeCookie(event) {

		const value = !event.target.checked;
		this.userService.changeAnalytics(value).subscribe(d => {
			this.userService.changeFacebook(value).subscribe(data => {
				this.user.cookieEnabled = value;
				this.user.cookieOptions = data.cookieOptions;
				this.cookForm.get('cookieEnabled')?.setValue(this.user.cookieEnabled);
				this.cookForm.get('facebook')?.setValue(this.user.cookieOptions.facebook);
				this.cookForm.get('analytics')?.setValue(this.user.cookieOptions.analytics);
				if (this.user.cookieOptions.analytics) {
					//this.pixel.initialize();
				} else {
					//this.pixel.remove();
				}
			}, error => {
				this.toastService.show(error);
			});
		}, error => {
			this.toastService.show(error);
		});

	}

	changeNotification(event) {

		this.userService.changeNotification(!event.target.checked).subscribe(value => {
			this.user.notificationEnabled = this.notifForm.get('notificationEnabled').value;
			const notificationOptions = {
				forum: this.notifForm.get('notificationEnabled').value,
				offer: this.notifForm.get('notificationEnabled').value,
				message: this.notifForm.get('notificationEnabled').value,
				event: this.notifForm.get('notificationEnabled').value,
			};
			this.userService.changeNotificationOptions(notificationOptions).subscribe(data => {
				this.user.notificationOptions = data.notificationOptions;
				this.notifForm.get('forum')?.setValue(this.user.notificationOptions.forum);
				this.notifForm.get('offer')?.setValue(this.user.notificationOptions.offer);
				this.notifForm.get('message')?.setValue(this.user.notificationOptions.message);
				this.notifForm.get('event')?.setValue(this.user.notificationOptions.event);
			});

		}, error => {
			this.toastService.show(error);
		});
	}

	changeAnalytics(event) {
		this.userService.changeAnalytics(this.user.cookieOptions.analytics).subscribe(value => {
		}, error => {
			this.toastService.show(error);
		});
	}

	changePassword() {
		const confirmPassword = this.authForm.value.confirmPassword;
		const password = this.authForm.value.password;
		this.userService.changePassword(password, confirmPassword).subscribe(value => {
			this.toastService.show('Le mot de passe a bien été changé.');
		}, error => {
			this.toastService.show(error);
		});
	}

	public onPasswordToggle(): void {
		this.showPassword = !this.showPassword;
	}

	public onConfirmPasswordToggle(): void {
		this.showConfirmPassword = !this.showConfirmPassword;
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}

	adherent() {
		window.open('https://www.cftc.fr/adherer', '_blank');
	}

	openModalConfidenciality() {
		this.modalService.openConfidenciality(value => {
		});
	}

	changeSubPaper(evt: any, sub: SubscriptionPaper) {
		sub.isSub = !evt.target.checked
		// console.log(evt.target.checked)
		// sub.isSub = !evt.target.checked;
		this.subService.updatePaper(sub, this.user.inaric).subscribe(value => {
			this.init();
		});
	}

	changeSubNews(evt: any, sub: string) {
		const data = {
			cftc_app: this.subNewsForm.get('cftc_app').value,
			cftc_bi_mensuelle: this.subNewsForm.get('cftc_bi_mensuelle').value
		};
		data[sub] = !evt.target.checked;

		this.subService.updateNews(
			data
		).subscribe(value => {

		});
	}

	getTitle(title) {
		const titles = {
			'La Vie à Défendre': 'La Vie en bleu CFTC',
			VHS: 'La Lettre Confédérale'
		};
		if (titles.hasOwnProperty(title)) {
			return titles[title];
		}
		return title;
	}
}
