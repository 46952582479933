import {Component, OnInit} from "@angular/core";
import {ModalController} from "@ionic/angular";
import {ResponsiveService} from "../../../shared/services/utils/responsive.service";
import {EventService} from "../../../shared/services/event.service";

@Component({
	selector: "app-modal-ressourcerie",
	templateUrl: "./ressourcerie.component.html",
	styleUrls: ["./ressourcerie.component.scss"],
})
export class RessourcerieModalComponent implements OnInit {
	url: string;

	constructor(
		public modalController: ModalController,
		public responsive: ResponsiveService,
		public eventService: EventService) {

	}


	ngOnInit(): void {
	}

	dismissModal() {
		this.modalController.dismiss({
			dismissed: true,
		});
	}


}
