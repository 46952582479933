import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {IonTextarea, ModalController} from "@ionic/angular";
import {Router} from "@angular/router";
import {ForumService} from "../../../../../../../../shared/services/forum.service";
import {ForumSubject} from "../../../../../../../../shared/models/forum-subject";
import {ForumTopic} from "../../../../../../../../shared/models/forum-topic";
import {ToastService} from "../../../../../../../../shared/services/utils/toast.service";
import {File} from "../../../../../../../../shared/models/file";
import {CommonHelper} from "../../../../../../../../shared/helpers/common.helper";
import {ModalService} from "../../../../../../../../shared/services/utils/modal.service";

@Component({
	selector: "app-modal-subject-create",
	templateUrl: "./subject-create.component.html",
	styleUrls: ["./subject-create.component.scss"],
})
export class SubjectCreateModalComponent implements OnInit, AfterViewInit {
	subject: ForumSubject;
	uuid = "";
	error = "";
	cg = false;
	topic: any;
	topics: ForumTopic[] = [];
	sourceFile;
	isOffer = false;

	@ViewChild("myTextArea") private textArea: IonTextarea;

	constructor(
		private router: Router, private toastService: ToastService, private commonHelper: CommonHelper,
		public modalController: ModalController, private forumService: ForumService, private modalService: ModalService) {

	}


	ngOnInit(): void {

	}

	ngAfterViewInit() {
		/*	this.textArea.ionChange.subscribe(() => {
				this.textArea.autoGrow = true;
			});*/
		if (!this.isOffer) {
			this.forumService.getTopics().subscribe(value => {
				this.topics = value;
				if (!this.topic && this.topics.length > 0) {
					this.topic = '';
				}
				if (this.uuid !== "") {
					this.forumService.getSubject(this.uuid).subscribe(subject => {
						this.subject = subject;
					}, error => {
						this.toastService.show(error);
					});
				} else {
					const subject = new ForumSubject();
					for (const topic of this.topics) {
						if (topic.uuid === this.topic.uuid) {
							subject.topic = topic;
						}
					}
					this.subject = subject;
				}

			}, error => {
				this.toastService.show(error);
			});
		} else {
			this.forumService.getTopicOffer().subscribe(topic => {
				if (this.uuid !== "") {
					this.forumService.getSubject(this.uuid).subscribe(subject => {
						this.subject = subject;
					}, error => {
						this.toastService.show(error);
					});
				} else {
					const subject = new ForumSubject();
					subject.topic = topic;
					this.subject = subject;
				}

			}, error => {
				this.toastService.show(error);
			});
		}

	}

	dismissModal(close = false) {
		this.modalController.dismiss({
			dismissed: true,
			subject: this.subject,
			close
		});
	}

	async save() {
		await this.modalService.openLoading();
		this.forumService.addSubject(this.subject.topic.uuid, this.subject.title, this.subject.description, this.subject.files).subscribe(value => {
			this.modalService.closeLoading();
			this.subject.uuid = value.uuid;
			this.dismissModal();
		}, error => {
			this.toastService.show(error);
		});
	}

	async edit() {
		await this.modalService.openLoading();
		this.forumService.updateSubject(this.subject.uuid, this.subject.topic.uuid, this.subject.title, this.subject.description, this.subject.files).subscribe(value => {
			this.modalService.closeLoading();
			this.dismissModal();
		}, error => {
			this.toastService.show(error);
		});
	}

	archiver() {
		this.forumService.archiveSubject(this.subject.uuid).subscribe(value => {
			this.dismissModal(true);
		}, error => {
			this.toastService.show(error);
		});
	}

	delete() {
		this.forumService.deleteSubject(this.subject.uuid).subscribe(value => {
			this.dismissModal(true);
		}, error => {
			this.toastService.show(error);
		});
	}

	thematiqueChange(evt) {
		for (const topic of this.topics) {
			if (topic.uuid === evt.target.value) {
				this.subject.topic = topic;
			}
		}
	}

	async chooseFile(event) {
		const file = event.target.files[0];
		if (file.size / 1024 / 1024 < 10) {
			const newFile = new File();
			newFile.name = file.name;
			newFile.filename = await this.commonHelper.readFileAsync(file);
			this.subject.files.push(newFile);
		}
	}

	removeImage(index) {
		this.subject.files.splice(index, 1);
	}

}
